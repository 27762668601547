import React from "react";
import { Link, graphql } from "gatsby";
import { FormattedMessage } from "react-intl";
import LazyLoad from "react-lazyload";
import { INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Layout from "../components/layout";
import Seo from "../components/seo";
import ResultsMap from "../components/results-map";
import Results from "../components/results";

const OfferTemplate = ({ data, pageContext: context }) => {
  const {
    title,
    color,
    short_description,
    type,
    discount,
    icon,
    content,
    hotel,
  } = data.contentfulOffre;

  const {
    url: iconUrl,
    details: {
      image: { width: iconWidth, height: iconHeight },
    },
  } = icon.file;

  const siteUrl = data.site.siteMetadata.siteUrl;
  const allPages = data.allContentfulPage.nodes;

  const renderOptions = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        return (
          <Link to={`/${context.locale}/${node.data.target.slug}/`}>
            {children}
          </Link>
        );
      },
    },
  };

  function getParentPage() {
    return allPages.find((page) => page.category === "Offres");
  }

  return (
    <Layout noButton={type === "Offre ponctuelle"}>
      <Seo title={title} description={short_description}>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "${getParentPage().title}",
                  "item": "${siteUrl}/${context.locale}/${
            getParentPage().slug
          }/"
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": "${title}"
                }
              ]
            }
          `}
        </script>
      </Seo>
      <article>
        <section className="section section-gradient">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <p className="paragraph font-weight-bold text-yellow text-center">
                  {getParentPage().title}
                </p>
                <h1 className="text-center">{title}</h1>
                <hr />
              </div>
            </div>
            <div className="row mt-12 mt-md-20">
              <div
                className={
                  type === "Offre ponctuelle"
                    ? "col-md-6 col-xl-5 offset-xl-1"
                    : "col-8 col-md-5 col-lg-4 col-xl-3 offset-2 offset-md-1 offset-lg-2 offset-xl-3"
                }
              >
                <figure
                  className={`offer
                    ${
                      type === "Offre ponctuelle" ? "offer-featured " : ""
                    }offer-${color} h-auto`}
                >
                  <div className="offer-heading">
                    <p>{discount}</p>
                    {icon && (
                      <img
                        src={iconUrl}
                        width={iconWidth}
                        height={iconHeight}
                        alt={title}
                      />
                    )}
                  </div>
                  <figcaption className="offer-description">
                    <h3>{title}</h3>
                    <p>{short_description}</p>
                  </figcaption>
                </figure>
              </div>
              <div className="col-md-5 col-xl-4 offset-md-1 mt-8 mt-md-0">
                {content && renderRichText(content, renderOptions)}
                <p className="mt-md-8">
                  <Link to={`/${context.locale}/${getParentPage().slug}/`}>
                    <FormattedMessage id="other-offers" />
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>
        {type === "Offre ponctuelle" && (
          <aside className="section section-white text-center">
            <h2 className="mb-8">
              <mark>
                {hotel.length} <FormattedMessage id="results.hotel" />
                {hotel.length > 1 ? "s" : ""}
              </mark>{" "}
              <FormattedMessage id="results.match" />{" "}
              <FormattedMessage id="results.offer" />
            </h2>
            <div className="search-grid">
              <LazyLoad>
                <Results data={hotel} type="offer" />
                <div className="search-map">
                  <ResultsMap hotels={hotel} type="offer" />
                </div>
              </LazyLoad>
            </div>
          </aside>
        )}
      </article>
    </Layout>
  );
};

export default OfferTemplate;

export const query = graphql`
  query ($id: String!, $slug: String!, $locale: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allSitePage(filter: { context: { id: { eq: $id } } }) {
      nodes {
        path
        context {
          locale
        }
      }
    }
    allContentfulPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        slug
        category
      }
    }
    contentfulHotel(
      contentful_id: { eq: "7Ghq3MGGxup1gZhcPpUnH" }
      node_locale: { eq: $locale }
    ) {
      meta_description
      hero_image {
        file {
          url
        }
      }
    }

    contentfulOffre(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      color
      short_description
      type
      discount
      icon {
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
      }
      content {
        raw
        references {
          contentful_id
          slug
        }
      }
      hotel {
        id
        title
        phone
        slug
        node_locale
        lat
        lon
        address
        booking_id
        spirit {
          slug
        }
      }
    }
  }
`;
